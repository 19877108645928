import { LinearProgress } from '@material-ui/core';
import { PhotoGrid } from 'Common/Elements';
import { DOMHelper } from 'Common/Helpers';
import { usePhotosEndpoint } from 'Endpoints';
import { PhotoOverview } from 'Models/PhotoModels';
import React, { useEffect, useState } from 'react';

export interface IFavoritesProps {}

export const Favorites = (props: IFavoritesProps) => {
	const ep = usePhotosEndpoint();
	const [photos, setPhotos] = useState<PhotoOverview[]>([]);

	useEffect(() => {
		DOMHelper.setPageTitle('My Favorites');
		ep.GetFavorites().then(r => r && setPhotos(r));
	}, []);

	if (ep.IsLoading) {
		return <LinearProgress />;
	}

	return (
		<React.Fragment>
			<h2>My Favorites</h2>
			<PhotoGrid photos={photos} />
		</React.Fragment>
	);
};
