import React from 'react';
import { PhotoModels } from 'Models';

import { Link } from 'react-router-dom';

import {
	GridList,
	GridListTile,
	GridListTileBar,
	makeStyles,
	Theme,
	createStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { Favorite } from 'Photos';

export interface IPhotoGridProps {
	photos: PhotoModels.PhotoOverview[];
	/** hides the favorite component in each photo grid item */
	hideFavorite?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
			overflow: 'hidden',
		},
		gridList: {
			width: '100%',
		},
		titleBar: {
			background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
		},
		icon: {
			color: 'white',
		},
		// had to add extra styles since making Link a child of GridListTile messed with its assumed
		// use case of having an img as a direct child
		link: {
			display: 'block',
			height: '100%',
			width: '100%',
		},
		img: {
			backgroundSize: 'cover',
			width: '100%',
		},
	})
);

export const PhotoGrid = (props: IPhotoGridProps) => {
	const classes = useStyles();
	const theme = useTheme();

	// change the number of columns for the gridList based on breakpoint
	// could maybe be improved or moved to a central location, but this is just a starting point
	const isXs = useMediaQuery(theme.breakpoints.only('xs'));
	const isSm = useMediaQuery(theme.breakpoints.only('sm'));
	const isMd = useMediaQuery(theme.breakpoints.only('md'));
	const gridCols = () => {
		if (isXs) {
			return 1;
		} else if (isSm) {
			return 2;
		} else if (isMd) {
			return 3;
		} else {
			return 4;
		}
	};

	return (
		<div className={classes.root}>
			<GridList cellHeight={250} cols={gridCols()} className={classes.gridList}>
				{props.photos.map(p => (
					<GridListTile key={p.id}>
						<Link to={'/photos/' + p.id} className={classes.link}>
							<img className={classes.img} src={p.url + '/500'} />
						</Link>
						<GridListTileBar
							title={p.title}
							titlePosition="top"
							actionIcon={
								props.hideFavorite === undefined || !props.hideFavorite ? (
									<Favorite photoId={p.id} isFavorited={p.isFavorited} />
								) : null
							}
							actionPosition="left"
							className={classes.titleBar}
						/>
					</GridListTile>
				))}
			</GridList>
		</div>
	);
};
