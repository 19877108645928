import { useNotifications } from 'Common/Notifications';
import { AddPhoto, EditPhoto, PhotoDetails, PhotoOverview } from 'Models/PhotoModels';
import { Endpoint } from './Endpoint';

export const usePhotosEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('photos');

	const GetFavorites = () =>
		ep.Get<PhotoOverview[]>('favorites').catch(() => {
			useError('Error getting favorite photos');
			return [] as PhotoOverview[];
		});

	const GetPhoto = (id: number) =>
		ep.Get<PhotoDetails>(id).catch(() => {
			useError('Error getting photo');
			return undefined;
		});

	const Add = async (photos: AddPhoto[]) => {
		let uploaded: number = 0;

		for (const p of photos) {
			const response = await ep.Post<void>(p.toFormData()).catch(() => {
				useError('Error adding photo');
				return undefined;
			});
			response !== undefined && (uploaded += 1);
		}
		if (uploaded === photos.length) {
			useSuccess('Photos uploaded');
		}
		return uploaded;
	};

	const Edit = (dto: EditPhoto) =>
		ep
			.Put<PhotoDetails>(dto.id, dto)
			.then(r => {
				useSuccess('Photo saved');
				return r;
			})
			.catch(() => {
				useError('Error saving photo');
				return undefined;
			});

	const AddFavorite = (id: number) =>
		ep
			.Post<number>(null, `favorite/${id}`)
			.then(r => {
				useSuccess('Photo favorited');
				return r;
			})
			.catch(() => {
				useError('Error favoriting photo');
				return undefined;
			});

	const RemoveFavorite = (id: number) =>
		ep
			.Delete<number>('', `favorite/${id}`)
			.then(r => {
				useSuccess('Photo removed from favorites');
				return r;
			})
			.catch(() => {
				useError('Error removing photo from favorites');
				return undefined;
			});

	const Download = (id: number) =>
		ep.Download(`${id}/contents`).catch(() => {
			useError('Error getting photo contents');
			return undefined;
		});

	return { GetFavorites, GetPhoto, Add, Edit, AddFavorite, RemoveFavorite, Download, IsLoading: ep.IsLoading };
};
