import { PhotoOverview } from './PhotoModels';

/** album overview data used for returning both 'all' and 'my' albums */
export class Album {
	id: number = 0;
	name: string = '';
	description: string = '';
	/** The full Url to the album's main photo contents */
	photoUrl: string = '';
	photoId: number = 0;
}

export class AlbumDetails {
	id: number = 0;
	name: string = '';
	description: string = '';
	userName: string = '';
	photos: PhotoOverview[] = [];
	createdBy: string = '';
}

export class AddAlbum {
	name: string = '';
	description: string = '';
	isShared: boolean = false;
}
