/* tslint:disable: no-import-side-effect */
import 'core-js';
import 'regenerator-runtime/runtime';
import 'typeface-roboto-condensed';
import 'Assets/global.css';

/* tslint:enable: no-import-side-effect */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
// for some reason, doing '* as' makes this work but just a straight 'import settings' returns undefined
import settings from 'settings';

import App from 'Layout/App';
import { AppProvider } from 'Context/AppProvider';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'Common/Auth/config';

Sentry.init({ dsn: settings.sentry.dsn, environment: settings.sentry.environment });

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
	<AppProvider>
		<MsalProvider instance={msalInstance}>
			<App />
		</MsalProvider>
	</AppProvider>,
	document.getElementById('app')
);

declare var module: any;
if (module.hot) {
	module.hot.accept();
}
