import { AccountInfo } from '@azure/msal-browser';

type idTokenClaims = {
	family_name: string;
	given_name: string;
	groups: string[];
};

export class LoggedInUser {
	email: string = '';
	familyName: string = '';
	givenName: string = '';
	name: string = '';
	preferredUsername: string = '';
	roles: string[] = [];
	guid: string = '';

	constructor(account: AccountInfo) {
		const claims = account.idTokenClaims as idTokenClaims;
		console.log(account);
		this.email = account.username;
		this.name = account.name ?? '';
		this.preferredUsername = account.username;
		this.guid = account.localAccountId;
		this.roles = claims?.groups;
		this.familyName = claims?.family_name;
		this.givenName = claims?.given_name;
	}
}

export class SearchResult {
	title: string = '';
	description: string = '';
	id: number = 0;
	type: string = '';
	/** url of the search result's image contents */
	url: string = '';
}
